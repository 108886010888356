const getStylesFromVariant = {
  header_1: {
    fontSize: '32px',
    fontWeight: 'bold',
    lineHeight: '38px',
  },
  header_2: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '33px',
  },
  heading_1: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '19px',
  },
  heading_2: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '17px',
  },
  body_1: {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '17px',
  },
  body_2: {
    fontSize: '10px',
    fontWeight: 'normal',
    lineHeight: '14px',
  },
  graph_heading: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  graph_axis: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '17px',
  },
  graph_labels: {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '17px',
  },
};

export { getStylesFromVariant };
