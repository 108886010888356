// Copyright 2021 SeekOps Inc.
// react
import { FC } from "react";

// third-party
import Store from "./store";
import { useNavigate } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

// first-party
import "./App.scss";
import AppContent from "./AppContent";
import ability from "./config/ability";
import { AbilityContext } from "./Can";
import GISMapContextProvider from "./GISMapContext/GISMapContextProvider";
import { CustomNavigationClient } from "./utils/CustomNavigationClient";

interface appProps {
  passThroughPath?: string;
  passThroughId?: number;
  pca: IPublicClientApplication;
}

const App: FC<appProps> = (props: appProps) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  props.pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={props.pca}>
      <ReduxProvider store={Store}>
        <GISMapContextProvider>
          <AbilityContext.Provider value={ability}>
            <AppContent />
          </AbilityContext.Provider>
        </GISMapContextProvider>
      </ReduxProvider>
    </MsalProvider>
  );
};

export default App;
