const NAMESPACE = "celsius_by_seekops";

type StorageKey =
  | "abilities"
  | "companies"
  | "company"
  | "company_name"
  | "emissions_preferences"
  | "EULA"
  | "flightpath_preferences"
  | "i18n"
  | "JWT"
  | "JWTr"
  | "offline_locale"
  | "offline_dark_mode"
  | "pass_through_path"
  | "refresh_expiry"
  | "site_id"
  | "theme"
  | "user"
  | "user_details"
  | "user_units";

/**
 * Get the full key with namespace prefix
 * @param key - The original key without namespace
 * @returns The key with namespace prefix
 */
const getPrefixedKey = (key: StorageKey): string => {
  return `${NAMESPACE}_${key}`;
}

/**
 * Set an item in localStorage
 * @param key - The key under which the value is stored
 * @param value - The value to store
 */
export const setLocalStorageItem = (key: StorageKey, value: any): void => {
  try {
    const prefixedKey = getPrefixedKey(key);
    localStorage.setItem(prefixedKey, value);
  } catch (error) {
    console.error(`Error setting item in localStorage: ${error}`);
  }
};

/**
 * Get an item from localStorage
 * @param key - The key of the item to retrieve
 * @returns The retrieved value or null if the key does not exist
 */
export const getLocalStorageItem = (key: StorageKey): any | null => {
  try {
    const prefixedKey = getPrefixedKey(key);
    const serializedValue = localStorage.getItem(prefixedKey);

    return serializedValue;
  } catch (error) {
    console.error(`Error getting item from localStorage: ${error}`);
    return null;
  }
};

/**
 * Remove an item from localStorage
 * @param key - The key of the item to remove
 */
export const removeLocalStorageItem = (key: StorageKey): void => {
  try {
    const prefixedKey = getPrefixedKey(key);
    localStorage.removeItem(prefixedKey);
  } catch (error) {
    console.error(`Error removing item from localStorage: ${error}`);
  }
};

/**
 * Clear all items in the namespace from localStorage
 */
export const clearLocalStorageNamespace = (): void => {
  try {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(NAMESPACE)) {
        localStorage.removeItem(key);
      }
    });
  } catch (error) {
    console.error(`Error clearing namespace in localStorage: ${error}`);
  }
};

/**
 * Clear specific keys in the namespace from localStorage
 * @param keys - An array of keys to remove
 */
export const clearLocalStorageNamespaceByKeys = (keys: StorageKey[]): void => {
  try {
    keys.forEach((key) => {
      const prefixedKey = getPrefixedKey(key);
      localStorage.removeItem(prefixedKey);
    });
  } catch (error) {
    console.error(`Error clearing specific keys in namespace from localStorage: ${error}`);
  }
};
