// third-party
import { Reducer } from "redux";

//first-party
import {
  notificationState,
  notificationActionTypes,
  NotificationType,
} from "./notification.types";

export const initialState: notificationState = {
  isOpen: false,
  origin: "",
  message: "",
  actionText: "",
  isActionOpen: false,
  notificationType: NotificationType.default,
};

//TODO: This should be expanded to include support for multiple
//notification instances. Right now a new notification will override
//an existing one
//--Added by Mario Robles @ Dec 21, 2021

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const notificationReducer: Reducer<notificationState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    //Type Specific Action Type Cases
    case notificationActionTypes.SET_ERROR_NOTIFICATION_OPEN: {
      let newState: notificationState = {
        ...state,
        isOpen: true,
        origin: action.origin,
        message: action.message,
        actionText: action.actionText,
        notificationType: NotificationType.error,
      };
      return newState;
    }
    case notificationActionTypes.SET_INFO_NOTIFICATION_OPEN: {
      let newState: notificationState = {
        ...state,
        isOpen: true,
        origin: action.origin,
        message: action.message,
        actionText: action.actionText,
        notificationType: NotificationType.info,
      };
      return newState;
    }
    case notificationActionTypes.SET_SUCCESS_NOTIFICATION_OPEN: {
      let newState: notificationState = {
        ...state,
        isOpen: true,
        origin: action.origin,
        message: action.message,
        actionText: action.actionText,
        notificationType: NotificationType.success,
      };
      return newState;
    }
    case notificationActionTypes.SET_WARNING_NOTIFICATION_OPEN: {
      let newState: notificationState = {
        ...state,
        isOpen: true,
        origin: action.origin,
        message: action.message,
        actionText: action.actionText,
        notificationType: NotificationType.warning,
      };
      return newState;
    }
    case notificationActionTypes.SET_DEFAULT_NOTIFICATION_OPEN: {
      let newState: notificationState = {
        ...state,
        isOpen: true,
        origin: action.origin,
        message: action.message,
        actionText: action.actionText,
        notificationType: NotificationType.default,
      };
      return newState;
    }
    //********************************************/
    case notificationActionTypes.SET_NOTIFICATION_ACTION_TEXT: {
      let newState: notificationState = {
        ...state,
        actionText: action.actionText,
      };
      return newState;
    }
    case notificationActionTypes.SET_NOTIFICATION_MESSAGE: {
      let newState: notificationState = {
        ...state,
        message: action.message,
      };
      return newState;
    }
    case notificationActionTypes.SET_NOTIFICATION_ORIGIN: {
      let newState: notificationState = {
        ...state,
        origin: action.origin,
      };
      return newState;
    }
    case notificationActionTypes.SET_NOTIFICATION_TYPE: {
      let newState: notificationState = {
        ...state,
        notificationType: action.notificationType,
      };
      return newState;
    }
    case notificationActionTypes.CLOSE_NOTIFICATION: {
      let newState: notificationState = {
        ...state,
        isOpen: false,
      };
      return newState;
    }
    case notificationActionTypes.OPEN_NOTIFICATION: {
      let newState: notificationState = {
        ...state,
        isOpen: true,
      };
      return newState;
    }
    case notificationActionTypes.CLOSE_ACTION: {
      let newState: notificationState = {
        ...state,
        isActionOpen: false,
      };
      return newState;
    }
    case notificationActionTypes.OPEN_ACTION: {
      let newState: notificationState = {
        ...state,
        isActionOpen: true,
      };
      return newState;
    }
    case notificationActionTypes.RESET: {
      let newState: notificationState = {
        ...state,
        isOpen: false,
        origin: "",
        message: "",
        actionText: "",
        notificationType: NotificationType.default,
      };
      return newState;
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default notificationReducer;
