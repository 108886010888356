// Thirdd party
import { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';

// First party
import { getStylesFromVariant } from './utils';

type TextVariants = 
  | 'header_1'
  | 'header_2'
  | 'heading_1'
  | 'heading_2'
  | 'body_1'
  | 'body_2'
  | 'graph_heading'
  | 'graph_axis'
  | 'graph_labels';

interface TypefaceProps extends TypographyProps {
  textVariant?: TextVariants;
  children: string;
}

const Typeface: FC<TypefaceProps> = ({ textVariant, children, ...typographyProps }) => {
  const textStyles = textVariant
    ? getStylesFromVariant[textVariant]
    : getStylesFromVariant['body_1'];

  return (
    <Typography sx={{ ...textStyles }} {...typographyProps}>
      {children}
    </Typography>
  );
};

export default Typeface;
