// Copyright 2021 SeekOps Inc.
// react
import { Suspense, useCallback, useContext, useEffect, useState } from "react";

// third-party
import getTheme from "./config/theme"; // default theme
import { Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Grid } from "@mui/material";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { equals } from "lodash/fp";

// first-party
import i18n, { Locale } from "./config/i18n";
import "./App.scss";
import Authentication from "./components/Authentication";
import i18next from "i18next";
import { NavigationTabs } from "./components/Navigation/NavigationTabs.enum";
import { AbilityContext } from "./Can";
import { RootState } from "./store";
import useAuthentication from "./components/Authentication/useAuthentication";
import AsyncSites from "./components/Sites";
import AsyncNotFound from "./components/NotFound";
import AsyncReport from "./components/Report";
import AsyncSurveys from "./components/Surveys";
import AsyncProjects from "./components/Projects";
import AsyncProject from "./components/Project";
import AsyncSurvey from "./components/SurveyRoute";
import AsyncImporter from "./components/Importer";
import AsyncFluxPlane from "./components/FluxPlaneView";
import AsyncHelp from "./components/Help";
import AsyncLicense from "./components/License";
import AsyncAbout from "./components/About";
import AsyncPrivacy from "./components/PrivacyPolicy";
import AsyncInactivityMonitor from "./components/AuthenticationMonitor";
import AsyncReduxNotification from "./components/Notification/WithRedux";
import AppRibbon from "./AppRibbon";
import AdminHome from "./components/Home/AdminHome";
import AnalystHome from "./components/Home/AnalystHome";
import PilotHome from "./components/Home/PilotHome";
import UserManagement from "./components/UserManagement/UserManagement";
import UserDetail from "./components/UserManagement/UserDetail";
import { TableContextProvider } from "./components/UI/Table/TableContext";
import { getLocalStorageItem } from "./utils/LocalStorage.utils";

const navigationTabs = [
  //TODO: When a tab is not authorize it will not appear
  //however it will still be accessible and throws error
  /* 
  Material-UI: the value provided `/fluxplane` to the Tabs component is invalid.
  None of the Tabs children have this value.
  You can provide on e of the following values: /surveys, /sites, /overview, /import.
  */
  {
    label: "Surveys",
    path: "/" + NavigationTabs.Surveys,
  },
  {
    label: "Sites",
    path: "/" + NavigationTabs.Sites,
  },
  {
    label: "Overview",
    path: "/" + NavigationTabs.Overview,
  },
  {
    label: "Import",
    path: "/" + NavigationTabs.Import,
  },
  {
    label: "Fluxplane",
    path: "/" + NavigationTabs.Fluxplane,
  },
  {
    label: "Projects",
    path: "/" + NavigationTabs.Projects,
  },
  {
    label: "survey_quality_assurance",
    path: "/" + NavigationTabs.SurveyQuality,
  },
  {
    label: "surveys_post_quality_assurance",
    path: "/" + NavigationTabs.SurveyPostQA,
  },
  {
    label: "Report",
    path: "/" + NavigationTabs.Report,
  },
];

const AppContent = (props: any) => {
  const abilities = useContext(AbilityContext);
  const dispatch = useDispatch();

  const { authenticate } = useAuthentication();
  const isLicenseAccepted = useSelector(
    (state: RootState) => state.authentication.eulaAccepted
  );
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const locale = useSelector((state: RootState) => state.language.locale);

  const themeType = useSelector((state: RootState) => state.theme.themeType);
  const theme = getTheme(themeType);
  const token = useSelector((state: RootState) => state.authentication.token);
  const currentMSALToken = useSelector(
    (state: RootState) => state.authentication.MSALAccessToken
  );
  const [MSALToken, setMSALToken] = useState<string>("");

  // dispatch to store in redux & localstorage
  const storeMSALToken = useCallback(
    (MSALToken: string) => {
      dispatch({
        type: "@@authentication/SET_MSAL_ACCESS_TOKEN",
        MSALAccessToken: MSALToken,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then((response) => {
          if (response) {
            // store the token
            storeMSALToken(response.accessToken);
          }
        });
    }
  }, [account, instance, storeMSALToken]);

  useEffect(() => {
    if (currentMSALToken && !equals(currentMSALToken, MSALToken)) {
      authenticate();
      setMSALToken(currentMSALToken);
    }
  }, [currentMSALToken, setMSALToken, MSALToken, authenticate]);

  useEffect(() => {
    const previouslySelectedLanguage: Locale = locale;
    if (previouslySelectedLanguage) {
      i18n.changeLanguage(previouslySelectedLanguage);

      const lang: string = locale.split("-")[0];
      document.documentElement.lang = lang;
      document.documentElement.dir = i18next.dir(previouslySelectedLanguage);
    }
  }, [locale]);

  // users that are not authenticated in can only see the sign-in page and any deep
  // linked paths will simply redirect to the root route (sign-in page)
  let routes: JSX.Element = <div></div>;

  // when the user is not authenticated only allow path: /
  if (!token) {
    routes = (
      <Routes>
        <Route key={"authenticate"} path="/" element={<Authentication />} />
        <Route key={"default"} path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  if (token && isLicenseAccepted === true) {
    let redirect: string = "/";
    let storedRedirect = getLocalStorageItem("pass_through_path");
    if (storedRedirect) {
      redirect = storedRedirect;
    } else {
      if (navigationTabs && navigationTabs.length) {
        // defaults to home tab
        const analystHome = abilities.can("fpRun", "fluxplane");
        const pilotHome = abilities.can("create", "survey_quality_assurance");
        const adminHome = abilities.can("access", "soadmin");
        if (adminHome) {
          redirect = `/${NavigationTabs.HomeAdmin}`;
        } else if (pilotHome) {
          redirect = `/${NavigationTabs.HomePilot}`;
        } else if (analystHome) {
          redirect = `/${NavigationTabs.HomeAnalyst}`;
        } else {
          redirect = navigationTabs[0].path;
        }
      }
    }

    routes = (
      <Routes>
        <Route
          path="/help"
          element={
            <Suspense fallback={<>Loading Help...</>}>
              <AsyncHelp />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<>Loading About...</>}>
              <AsyncAbout />
            </Suspense>
          }
        />
        <Route
          path="/privacy"
          element={
            <Suspense fallback={<>Loading Privacy...</>}>
              <AsyncPrivacy />
            </Suspense>
          }
        />
        {/* Home */}
        {abilities.can("access", "soadmin") && (
          <Route
            path={`/${NavigationTabs.HomeAdmin}`}
            element={
              <Suspense fallback={<>Loading Home...</>}>
                <AdminHome />
              </Suspense>
            }
          />
        )}
        {abilities.can("fpRun", "fluxplane") && (
          <Route
            path={`/${NavigationTabs.HomeAnalyst}`}
            element={
              <Suspense fallback={<>Loading Home...</>}>
                <AnalystHome />
              </Suspense>
            }
          />
        )}
        {abilities.can("create", "survey_quality_assurance") && (
          <Route
            path={`/${NavigationTabs.HomePilot}`}
            element={
              <Suspense fallback={<>Loading Home...</>}>
                <PilotHome />
              </Suspense>
            }
          />
        )}
        {/* End Home */}
        {abilities.can("access", "soadmin") && (
          <Route
            path={`/${NavigationTabs.UserManagement}`}
            element={
              <Suspense fallback={<>Loading User Management...</>}>
                <UserManagement />
              </Suspense>
            }
          />
        )}
        {abilities.can("access", "soadmin") && (
          <Route
            path={`/${NavigationTabs.UserManagement}/user/:id`}
            element={
              <Suspense fallback={<>Loading User...</>}>
                <UserDetail />
              </Suspense>
            }
          />
        )}
        {
          /**Surveys */
          abilities.can("read", NavigationTabs.Surveys) && (
            <Route
              path={`/${NavigationTabs.Surveys}`}
              element={
                <Suspense fallback={<>Loading Surveys...</>}>
                  <AsyncSurveys />
                </Suspense>
              }
            />
          )
        }
        {abilities.can("read", NavigationTabs.Surveys) && (
          <Route
            path={"/survey/:id"}
            element={
              <Suspense fallback={<>Loading Survey...</>}>
                <AsyncSurvey />
              </Suspense>
            }
          />
        )}
        {
          /** projects */
          abilities.can("read", NavigationTabs.Projects) && (
            <Route
              path={`/${NavigationTabs.Projects}`}
              element={
                <Suspense fallback={<>Loading Projects...</>}>
                  <AsyncProjects />
                </Suspense>
              }
            />
          )
        }
        {
          /** projects */
          abilities.can("read", NavigationTabs.Projects) && (
            <Route
              path={`/project/:id`}
              element={
                <Suspense fallback={<>Loading Project...</>}>
                  <AsyncProject />
                </Suspense>
              }
            />
          )
        }
        {
          /**Sites */
          abilities.can("read", NavigationTabs.Sites) && (
            <Route
              path={`/${NavigationTabs.Sites}`}
              element={
                <Suspense fallback={<>Loading Sites...</>}>
                  <AsyncSites />
                </Suspense>
              }
            />
          )
        }
        {
          /**Sites */
          abilities.can("read", NavigationTabs.Sites) && (
            <Route
              path={`/${NavigationTabs.Sites}/:name`}
              element={
                <Suspense fallback={<>Loading Sites...</>}>
                  <AsyncSites />
                </Suspense>
              }
            />
          )
        }
        {
          /**Import */
          abilities.can("read", NavigationTabs.Import) && (
            <Route
              path={`/${NavigationTabs.Import}`}
              element={
                <Suspense fallback={<>Loading Importer...</>}>
                  <AsyncImporter />
                </Suspense>
              }
            />
          )
        }

        {
          /**Fluxplane */
          abilities.can("read", NavigationTabs.Fluxplane) && (
            <Route
              path={`/${NavigationTabs.Fluxplane}`}
              element={
                <Suspense fallback={<>Loading Fluxplane...</>}>
                  <AsyncFluxPlane />
                </Suspense>
              }
            >
              <Route
                path={":id"}
                element={
                  <Suspense fallback={<>Loading Fluxplane...</>}>
                    <AsyncFluxPlane />
                  </Suspense>
                }
              />
            </Route>
          )
        }

        {
          /**Report */
          abilities.can("read", NavigationTabs.Report) && (
            <Route
              path={`/${NavigationTabs.Report}/`}
              element={
                <Suspense fallback={<>Loading Report...</>}>
                  <AsyncReport />
                </Suspense>
              }
            />
          )
        }
        <Route
          path="/404"
          element={
            <Suspense fallback={<>Loading Not Found...</>}>
              <AsyncNotFound />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate replace to={redirect} />} />
      </Routes>
    );
  }

  return (
    <ThemeProvider theme={theme || getTheme("light")}>
      <TableContextProvider>
        <CssBaseline />
        <div className={`App ${themeType}-mode`} aria-label="app-content">
          {token && !isLicenseAccepted && (
            <>
              <Suspense fallback={<>Loading EULA...</>}>
                <AsyncLicense />
              </Suspense>
            </>
          )}

          <div className="d-flex" style={{ display: "flex" }}>
            <main style={{ flex: "1 1 0", overflow: "hidden" }}>
              <Grid container wrap="nowrap">
                {token && isLicenseAccepted === true && (
                  <Grid item>
                    <AppRibbon />
                  </Grid>
                )}
                <Grid item xs>
                  <div className="mainBody-route">
                    <Suspense fallback={<div>loading...</div>}>
                      {routes}
                    </Suspense>
                  </div>
                  <Suspense fallback={<>Loading Notification...</>}>
                    <AsyncReduxNotification />
                  </Suspense>
                  <Suspense fallback={<>Loading Inactivity Monitor...</>}>
                    <AsyncInactivityMonitor />
                  </Suspense>
                </Grid>
              </Grid>
            </main>
          </div>
        </div>
      </TableContextProvider>
    </ThemeProvider>
  );
};

export default AppContent;
