// Module dependencies
import { FC } from 'react';
import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  grow?: boolean;
}

/**
 * holds a tab's contents
 *
 * @param props
 * @returns
 */
const TabPanel: FC<TabPanelProps> = ({ children, value, index, grow, ...other }) => {
  return (
    <div
      className={`tab-panel ${grow ? 'tab-panel_grow' : ''}`}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
