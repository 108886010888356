// React
import { FC, ReactNode } from 'react'

// Third party
import { Button, ButtonProps } from '@mui/material';

// First party
import './ChartButton.scss';

interface ChartButtonProps extends ButtonProps {
  children: ReactNode;
  handleClick?: (args: any) => void | any;
  variantColor?: 'success' | 'error' | 'info' | 'warning';
  customClassNames?: string;
}

const ChartButton: FC<ChartButtonProps> = ({ 
  children,
  variantColor = 'info',
  handleClick,
  customClassNames,
  ...props
 }) => (
  <Button
    className={`chartbutton ${props.disabled && 'chartbutton_disabled'} chartbutton_${variantColor} ${customClassNames}`}
    variant="outlined"
    onClick={handleClick}
    {...props}
  >
    {children}
  </Button>
);

export default ChartButton;
