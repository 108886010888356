// Third-party
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Box, IconButton, Tooltip, Tab, Tabs } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import i18next from "i18next";

// First-party
import useAnnouncements from "./useAnnouncements";
import BaseDialog, { DialogActionList } from "../BaseDialog/BaseDialog";
import TabPanel from "../SurveyDetails/SurveyDetailTabPanel";
import AnnouncementList from "./Announcements.list";
import { Announcement } from "./Announcements.interfaces";
import "./Announcements.scss";

// i18n
import EN_US from "./ResourceBundles/en_US.json";
import ES_MX from "./ResourceBundles/es_MX.json";
import AR_SA from "./ResourceBundles/ar_SA.json";
import FR_FR from "./ResourceBundles/fr_FR.json";
import IT_IT from "./ResourceBundles/it_IT.json";
import PT_BR from "./ResourceBundles/pt_BR.json";
import RU_RU from "./ResourceBundles/ru_RU.json";
import ZH_CN from "./ResourceBundles/zh_CN.json";

const Announcements: FC = () => {
  const {
    showAnnouncements,
    unreadAnnouncements,
    unreadAnnouncementsDetail,
    readAnnouncementsDetail,
    currentTab,
    handleHideAnnouncements,
    handleShowAnnouncements,
    handleTabChange,
  } = useAnnouncements();

  const { t } = useTranslation();

  i18next.addResourceBundle("en", "translation", EN_US);
  i18next.addResourceBundle("es", "translation", ES_MX);
  i18next.addResourceBundle("ar", "translation", AR_SA);
  i18next.addResourceBundle("pt", "translation", PT_BR);
  i18next.addResourceBundle("ru", "translation", RU_RU);
  i18next.addResourceBundle("zh", "translation", ZH_CN);
  i18next.addResourceBundle("fr", "translation", FR_FR);
  i18next.addResourceBundle("it", "translation", IT_IT);

  const actionList: DialogActionList[] = [
    {
      label: t("msg.announcements.close"),
      variantColor: "info",
      handleAction: handleHideAnnouncements,
    },
  ];

  const tabsList: {
    index: number;
    announcementList: Announcement[];
    unreadAnnouncements: boolean;
  }[] = [
    {
      index: 0,
      announcementList: unreadAnnouncementsDetail,
      unreadAnnouncements: true,
    },
    {
      index: 1,
      announcementList: readAnnouncementsDetail,
      unreadAnnouncements: false,
    },
  ];

  return (
    <>
      <Box className="icon-container">
        <Tooltip title={t("msg.announcements.title")} placement="right">
          <IconButton onClick={handleShowAnnouncements}>
            <Badge
              badgeContent={unreadAnnouncements.length}
              color="primary"
              className="icon-badge"
            >
              <CampaignIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>

      <BaseDialog
        open={showAnnouncements}
        title={"Announcements"}
        handleOnClose={handleHideAnnouncements}
        actionList={actionList}
        sx={{ zIndex: "3002 !important" }}
      >
        <Box>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label="Latest" />
            <Tab label="Prior" />
          </Tabs>
        </Box>

        {tabsList.map(({ index, announcementList, unreadAnnouncements }) => (
          <TabPanel key={index} index={index} value={currentTab}>
            <AnnouncementList
              announcementList={announcementList}
              unreadAnnouncements={unreadAnnouncements}
              t={t}
            />
          </TabPanel>
        ))}
      </BaseDialog>
    </>
  );
};

export default Announcements;
