/**
 * Formats a date string into a human-readable datetime string according to specific options.
 * If the input value is undefined or an invalid date, it returns a placeholder string '-'.
 * The function is designed to display the date in 'America/Chicago' time zone with detailed time components.
 * 
 * @param value - The date string to be formatted.
 * @returns A formatted datetime string or a placeholder if the input is undefined or invalid.
 */
const formatAsDateTime = (value: string | undefined): string => {
  if (!value)  return '-';

  try {
    const date = new Date(value);

    const options: any = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'America/Chicago'
    };

    const readableDate = date.toLocaleString('en-US', options);

    return readableDate;
  } catch (error) {
    return '-';
  }
};

const extractNumberFromUrl = (url: string) => {
  const regex = /(\d+)\/$/;
  const match = url.match(regex);

  return match ? parseInt(match[1], 10) : null;
}

export { formatAsDateTime, extractNumberFromUrl };
