// Copyright 2021 SeekOps Inc.

// third party
import { Suspense, useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  List,
  ListItem,
  Tooltip,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// first party
import { ReactComponent as ReportIcon } from "../src/assets/img/reportIcon.svg";
import { ReactComponent as SurveyIcon } from "../src/assets/img/SurveysIcon.svg";
import { ReactComponent as SitesIcon } from "../src/assets/img/SitesIcon.svg";
import { ReactComponent as ProjectsIcon } from "../src/assets/img/ProjectsIcon.svg";
import { ReactComponent as LogoIcon } from "../src/assets/img/logo-white.svg";
import { ReactComponent as UserIcon } from "../src/assets/img/UserIcon.svg";

import { NavigationTabs } from "./components/Navigation/NavigationTabs.enum";
import MenuButton from "./components/MenuButton";
import { AbilityContext } from "./Can";
import NotificationCenter from "./components/NotificationCenter";
import Announcements from "./components/Announcements/Announcements";
import useSurveyDialog from "./components/Survey/hooks/useSurveyDialog";

const AppRibbon = () => {
  const abilities = useContext(AbilityContext);

  const { handleCloseSurveyDialog } = useSurveyDialog();

  const homeNavigationRoute = () => {
    if (abilities.can("access", "soadmin")) {
      return `/${NavigationTabs.HomeAdmin}`;
    }
    if (abilities.can("create", "survey_quality_assurance")) {
      return `/${NavigationTabs.HomePilot}`;
    }
    if (abilities.can("fpRun", "fluxplane")) {
      return `/${NavigationTabs.HomeAnalyst}`;
    }
    return "/";
  };

  return (
    <Box
      sx={{ width: "45px", height: "100vh" }}
      onClick={handleCloseSurveyDialog}
    >
      <ErrorBoundary fallback={<div>app ribbon error</div>}>
        <aside
          className="w-25 side-menu-ribbon-rppt"
          style={{
            width: "45px",
            backgroundColor: "#0B3ADE",
            height: "100vh",
            display: "flex",
            position: "fixed",
            flexDirection: "column",
            zIndex: 13000,
          }}
        >
          <Suspense fallback={<></>}>
            <MenuButton className="navigation-icon" />
          </Suspense>

          <Suspense fallback={<></>}>
            <NotificationCenter />
          </Suspense>

          <Suspense fallback={<></>}>
            <Announcements />
          </Suspense>


          <Divider
            sx={{
              borderColor: "white",
              width: "75%",
              display: "flex",
              margin: "0 auto",
            }}
          />

          <List
            className="side-menu-navigation"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title={"Home"} placement="right" sx={{ zIndex: 1000 }}>
                <IconButton
                  component={Link}
                  to={homeNavigationRoute()}
                  className="navigation-icon"
                >
                  <HomeIcon style={{ fill: "#ffffff" }} />
                </IconButton>
              </Tooltip>
            </ListItem>

            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip
                title={"Surveys"}
                placement="right"
                sx={{ zIndex: 1000 }}
              >
                <IconButton
                  component={Link}
                  to={"/" + NavigationTabs.Surveys}
                  className="navigation-icon"
                >
                  <SurveyIcon />
                </IconButton>
              </Tooltip>
            </ListItem>

            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title={"Sites"} placement="right" sx={{ zIndex: 1000 }}>
                <IconButton
                  component={Link}
                  to={"/" + NavigationTabs.Sites}
                  className="navigation-icon"
                >
                  <SitesIcon />
                </IconButton>
              </Tooltip>
            </ListItem>

            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip
                title={"Projects"}
                placement="right"
                sx={{ zIndex: 1000 }}
              >
                <IconButton
                  component={Link}
                  to={"/" + NavigationTabs.Projects}
                  className="navigation-icon"
                >
                  <ProjectsIcon />
                </IconButton>
              </Tooltip>
            </ListItem>

            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title={"Report"} placement="right" sx={{ zIndex: 1000 }}>
                <IconButton
                  component={Link}
                  to={"/" + NavigationTabs.Report}
                  className="navigation-icon"
                >
                  <ReportIcon />
                </IconButton>
              </Tooltip>
            </ListItem>

            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title={"Import"} placement="right" sx={{ zIndex: 1000 }}>
                <IconButton
                  component={Link}
                  to={"/" + NavigationTabs.Import}
                  className="navigation-icon"
                >
                  <UploadFileIcon style={{ fill: "#ffffff" }} />
                </IconButton>
              </Tooltip>
            </ListItem>
            {abilities.can("access", "soadmin") ?
              <ListItem style={{ display: "flex", justifyContent: "center" }}>
                <Tooltip title={"User Management"} placement="right" sx={{ zIndex: 1000 }}>
                  <IconButton
                    component={Link}
                    to={"/" + NavigationTabs.UserManagement}
                    className="navigation-icon"
                  >
                    <UserIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
              : undefined}
          </List>

          <Divider
            sx={{
              borderColor: "white",
              width: "75%",
              display: "flex",
              margin: "0 auto 1rem auto",
            }}
          />

          <Tooltip title="SeekOps" placement="right" sx={{ zIndex: 2 }}>
            <IconButton
              component={Link}
              to={"https://www.seekops.com"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              <LogoIcon />
            </IconButton>
          </Tooltip>
        </aside>
      </ErrorBoundary>
    </Box>
  );
};

export default AppRibbon;
