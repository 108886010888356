import { FC } from 'react';
import { TFunction } from 'i18next';
import { Box, Card, CardContent, Typography } from '@mui/material';
import Markdown from 'react-markdown';

import { Announcement } from './Announcements.interfaces';
import Typeface from '../Typeface';
import { formatAsDateTime } from './Announcements.utils';

interface AnnouncementsCardProps {
  announcement: Announcement;
  t: TFunction<"translation", undefined>;
}

const AnnouncementsCard: FC<AnnouncementsCardProps> = ({ announcement, t }) => {
  const {
    title,
    message,
    creation_time,
    publish_time,
  } = announcement;

  return (
    <Card className="announcement-card">
      <CardContent className="announcement-card-content">
        <Typeface textVariant='graph_heading'>{title}</Typeface>

        <Box className="announcement-card-details">
          <Box className="announcement-card-info">
            <Typeface>{t("msg.announcements.created")}</Typeface>

            <Typeface textVariant='heading_2'>{formatAsDateTime(creation_time)}</Typeface>
          </Box>

          <Box className="announcement-card-info">
            <Typeface>{t("msg.announcements.published")}</Typeface>

            <Typeface textVariant='heading_2'>{formatAsDateTime(publish_time)}</Typeface>
          </Box>
        </Box>

        <Typography>
          <Markdown>
            {message}
          </Markdown>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AnnouncementsCard;
