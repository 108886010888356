import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import axiosInstance from "../../AJAX";
import { Announcement, AnnouncementsPayload } from "./Announcements.interfaces";
import { extractNumberFromUrl } from "./Announcements.utils";

/**
 * Custom hook for managing and interacting with announcements in an application.
 * Provides functionality to fetch, display, and manage read/unread states of announcements.
 *
 * @returns An object containing states and methods for managing announcements.
 */
const useAnnouncements = () => {
  // States to hold different classifications of announcements
  const [totalAnnouncements, setTotalAnnouncements] = useState<AnnouncementsPayload[]>([]);
  const [unreadAnnouncements, setUnreadAnnouncements] = useState<AnnouncementsPayload[]>([]);
  const [readAnnouncements, setReadAnnouncements] = useState<AnnouncementsPayload[]>([]);

  // States to hold the IDs and Urls of unread and read announcements
  const [unreadAnnouncementsIds, setUnreadAnnouncementsIds] = useState<number[]>([]);
  const [readAnnouncementsIds, setReadAnnouncementsIds] = useState<number[]>([]);
  const [unreadAnnouncementsUrls, setUnreadAnnouncementsUrls] = useState<string[]>([]);
  const [readAnnouncementsUrls, setReadAnnouncementsUrls] = useState<string[]>([]);

  // States to hold detailed data for unread and read announcements
  const [unreadAnnouncementsDetail, setUnreadAnnouncementsDetail] = useState<Announcement[]>([]);
  const [readAnnouncementsDetail, setReadAnnouncementsDetail] = useState<Announcement[]>([]);

  // State to control the visibility of announcements
  const [showAnnouncements, setShowAnnouncements] = useState<boolean>(false);
  
  // State to control the active tab in the UI
  const [currentTab, setCurrentTab] = useState<number>(0);

  // Constants for API endpoints and intervals
  const baseAnnouncementsUrl = '/api/announcements/';
  const pollingFrequency = 300000; // 5 Minutes

  /**
   * Shows the announcements UI panel.
   */
  const handleShowAnnouncements = () => setShowAnnouncements(true);

  /**
   * Hides the announcements UI panel and marks unread announcements as read.
   */
  const handleHideAnnouncements = async () => {
    setShowAnnouncements(false);

    if (unreadAnnouncementsIds.length > 0) {
      await markAnnouncementsAsRead(unreadAnnouncementsIds);

      fetchAnnouncements(false);
    }
  };

  /**
   * Splits announcements into read and unread categories.
   * @param announcements - Array of announcements to partition.
   * @returns An object containing sorted lists of read and unread announcements.
   */
  const sortAnnouncementsByRead = useCallback((announcements: AnnouncementsPayload[]) => {
    const partitionedAnnouncements = announcements.reduce((acc, announcement) => {
      if (announcement.read) {
        acc.read.push(announcement);

        const extractedIdFromUrl = extractNumberFromUrl(announcement.announcement.url);
        
        if (extractedIdFromUrl) {
          acc.readIds.push(extractedIdFromUrl);
        }

        acc.readUrls.push(announcement.announcement.url);
      } else {
        acc.unread.push(announcement);
        
        const extractedIdFromUrl = extractNumberFromUrl(announcement.announcement.url);

        if (extractedIdFromUrl) {
          acc.unreadIds.push(extractedIdFromUrl);
        }

        acc.unreadUrls.push(announcement.announcement.url);
      }
      return acc;
    }, {
      unread: [] as AnnouncementsPayload[],
      read: [] as AnnouncementsPayload[],
      unreadIds: [] as number[],
      readIds: [] as number[],
      unreadUrls: [] as string[],
      readUrls: [] as string[],
    });
  
    const sortedAnnouncements = {
      unread: partitionedAnnouncements.unread,
      read: partitionedAnnouncements.read,
      unreadIds: partitionedAnnouncements.unreadIds,
      readIds: partitionedAnnouncements.readIds,
      unreadUrls: partitionedAnnouncements.unreadUrls,
      readUrls: partitionedAnnouncements.readUrls,
    }

    return sortedAnnouncements;
  }, []);

  /**
   * Sorts announcements by their publish date.
   * @param announcements - Array of announcement details to sort.
   * @returns Sorted array of announcements.
   */
  const sortAnnouncementsByDate = useCallback((announcements: Announcement[]) => {
    return announcements.sort((a, b) => new Date(b.publish_time).getTime() - new Date(a.publish_time).getTime());
  }, []);

  /**
   * This code is currently unused in the present feature state; however, the service remains functional
   * and may be needed in future iterations.
   */
  // const fetchAnnouncementDetailById = useCallback(async (announcementId: number) => {
  //   try {
  //     const { data } = await axiosInstance.get(`${baseAnnouncementsUrl}${announcementId}`);

  //     return data;
  //   } catch (error) {
  //     console.log(error);

  //     return {};
  //   }
  // }, [baseAnnouncementsUrl]);

  /**
   * This code is currently unused in the present feature state; however, the service remains functional
   * and may be needed in future iterations.
   * 
   * Fetches details for multiple announcements by their IDs.
   * @param announcementsIds - Array of announcement IDs for which details are needed.
   * @returns A promise that resolves to an array of announcement details.
   */
  // const fetchAnnouncementsDetailByIds = useCallback(async (announcementsIds: number[]) => {
  //   try {
  //     const announcementDetailPromises = announcementsIds.map(id => fetchAnnouncementDetailById(id));
  //     const announcementDetailList = await Promise.all(announcementDetailPromises);

  //     return announcementDetailList;
  //   } catch (error) {
  //     console.log(error);
  //     return [];
  //   }
  // }, [fetchAnnouncementDetailById]);

  const fetchAnnouncementDetailByUrl = useCallback(async (announcementUrl: string) => {
    try {
      const { data } = await axiosInstance.get(announcementUrl);

      return data;
    } catch (error) {
      console.log(error);

      return {};
    }
  }, []);

  /**
   * Fetches details for multiple announcements by their Urls.
   * @param announcementsUrls - Array of announcement Urls for which details are needed.
   * @returns A promise that resolves to an array of announcement details.
   */
  const fetchAnnouncementsDetailByUrls = useCallback(async (announcementsUrls: string[]) => {
    try {
      const announcementDetailPromises = announcementsUrls.map(url => fetchAnnouncementDetailByUrl(url));
      const announcementDetailList = await Promise.all(announcementDetailPromises);

      return announcementDetailList;
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [fetchAnnouncementDetailByUrl]);

  /**
   * Retrieves and processes details for both unread and read announcements.
   * Sorts them by date and updates the state accordingly. Optionally shows the announcements UI after fetching.
   * @param unreadIds - Array of IDs for unread announcements.
   * @param readIds - Array of IDs for read announcements.
   * @param showAfterFetch - Boolean to decide if the UI should be shown after fetching details.
   */
  const getAnnouncementsDetails = useCallback(async (unreadUrls: string[], readUrls: string[], showAfterFetch: boolean) => {
    try {
      const [unreadDetails, readDetails] = await Promise.all([
        fetchAnnouncementsDetailByUrls(unreadUrls),
        fetchAnnouncementsDetailByUrls(readUrls)
      ]);

      const sortedUnreadDetails = sortAnnouncementsByDate(unreadDetails);
      const sortedReadDetails = sortAnnouncementsByDate(readDetails);
  
      setUnreadAnnouncementsDetail(sortedUnreadDetails);
      setReadAnnouncementsDetail(sortedReadDetails);
      
      setCurrentTab(unreadDetails.length > 0 ? 0 : 1);

      if (unreadDetails.length > 0) {
        setShowAnnouncements(showAfterFetch);
      }
    } catch (error) {
      console.log(error);
    }
  }, [sortAnnouncementsByDate, fetchAnnouncementsDetailByUrls]);

  /**
   * Fetches all announcements from the server and sorts them by read status.
   * Optionally shows the announcements UI after fetching.
   * @param showAfterFetch - Boolean to decide if the UI should be shown after fetching.
   */
  const fetchAnnouncements = useCallback(async (showAfterFetch: boolean) => {
    try {
      const { data } = await axiosInstance.get(baseAnnouncementsUrl);

      setTotalAnnouncements(data);

      const {
        unread,
        read,
        unreadIds,
        readIds,
        unreadUrls,
        readUrls,
      } = sortAnnouncementsByRead(data);

      setUnreadAnnouncements(unread);
      setReadAnnouncements(read);
      setUnreadAnnouncementsIds(unreadIds);
      setReadAnnouncementsIds(readIds);
      setUnreadAnnouncementsUrls(unreadUrls);
      setReadAnnouncementsUrls(readUrls);

      getAnnouncementsDetails(unreadUrls, readUrls, showAfterFetch);

    } catch (error) {
      console.log(error);
    }
  }, [baseAnnouncementsUrl, getAnnouncementsDetails, sortAnnouncementsByRead]);

  /**
   * Fetches announcements that have been marked as unread since the last login.
   * @returns A promise that resolves to the data of unread announcements.
   */
  const fetchUnreadAnnouncements = async () => {
    try {
      const { data } = await axiosInstance.get(`${baseAnnouncementsUrl}?unread_since_last_login`);

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Fetches specific announcements by their IDs.
   * @param announcements - Array of announcement IDs to fetch.
   * @returns A promise that resolves to the data of specific announcements.
   */
  const fetchAnnouncementsByIds = async (announcements: number[]) => {
    try {
      const { data } = await axiosInstance.get(`${baseAnnouncementsUrl}?announcement_ids=${announcements}`);

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Marks specified announcements as read.
   * @param announcementIds - Array of announcement IDs to be marked as read.
   */
  const markAnnouncementsAsRead = async (announcementIds: number[]) => {
    const patchData = {
      announcement_ids: announcementIds,
    };

    try {
      await axiosInstance.patch(
        `${baseAnnouncementsUrl}mark_as_read`,
        patchData,
      );
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Sets up an interval for fetching announcements periodically and fetches announcements
   * on initial render. Also cleans up the interval when the component is unmounted.
   */
  useEffect(() => {
    fetchAnnouncements(true);
    const interval = setInterval(() => fetchAnnouncements(false), pollingFrequency);

    return () => clearInterval(interval);
  }, [fetchAnnouncements]);
  
  /**
   * Handles changes to the current tab in the UI, setting the new active tab index.
   * @param event - The event object associated with the change of tab.
   * @param newValue - The new index of the active tab.
   */
  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return {
    totalAnnouncements,
    showAnnouncements,
    readAnnouncements,
    readAnnouncementsIds,
    readAnnouncementsDetail,
    unreadAnnouncements,
    unreadAnnouncementsIds,
    unreadAnnouncementsDetail,
    currentTab,
    readAnnouncementsUrls,
    unreadAnnouncementsUrls,
    setCurrentTab,
    handleTabChange,
    fetchAnnouncements,
    fetchUnreadAnnouncements,
    fetchAnnouncementsByIds,
    handleHideAnnouncements,
    handleShowAnnouncements,
    markAnnouncementsAsRead,
  };
};

export default useAnnouncements;
