// Third-Party
import { useDispatch, useSelector } from "react-redux";

// First-Party
import { RootState } from "../../../store";

const useSurveyDialog = () => {
  const dispatch = useDispatch();

  const {
    isOpen,
    surveyID,
    histogramData,
  } = useSelector((state: RootState) => state.surveyDialog);
  
  const handleCloseSurveyDialog = () => {
    if (isOpen) {
      dispatch({
        type: "@@surveyDialog/SET_SURVEY_INFO",
        isOpen: false,
        surveyID: -1,
        histogramData: null,
        pilot: "",
        serialNumber: "",
        startTime: "",
      });
    }
  };

  return {
    isOpen,
    surveyID,
    histogramData,
    handleCloseSurveyDialog,
  };
};

export default useSurveyDialog;
