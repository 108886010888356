// Module dependencies
import { FC, ReactNode } from "react";
import { ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typeface from "../Typeface";
import ChartButton from "../ChartButton/ChartButton";
import "./BaseDialog.scss";

export interface DialogActionList extends ButtonProps {
  label: string;
  variantColor: 'success' | 'error' | 'info' | 'warning';
  handleAction: any;
  renderAction?: Boolean;
}

interface BaseDialogProps extends DialogProps {
  title: string;
  handleOnClose: any;
  children: ReactNode;
  actionList?: DialogActionList[];
  customActions?: ReactNode;
}

const BaseDialog: FC<BaseDialogProps> = ({
  title,
  handleOnClose,
  children,
  actionList = [],
  customActions,
  ...props
}) => {
  return (
    <Dialog {...props}>
      <DialogTitle className="dialog-custom-title">
        <Typeface textVariant="heading_1">
          {title}
        </Typeface>

        <IconButton
          edge="end"
          color="primary"
          onClick={handleOnClose}
          aria-label="close full-screen dialog"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers className="dialog-custom-content">
        {children}
      </DialogContent>
      
      {actionList.length > 0 && (
        <DialogActions className="dialog-custom-action">
          {actionList.map(({ label, variantColor, handleAction, renderAction = true, ...buttonProps }) => (
            <span key={`${label}-action`}>
              {renderAction && (
                <ChartButton
                  onClick={handleAction}
                  variantColor={variantColor}
                  key={`${label}-${variantColor}`}
                  {...buttonProps}
                  >
                  {label}
                </ChartButton>
              )}
            </span>
          ))}
        </DialogActions>
      )}

      {actionList.length === 0 && customActions && (
        <>
          {customActions}
        </>
      )}
    </Dialog>
  );
};

export default BaseDialog;
