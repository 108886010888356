// Third-Party
import { FC, Fragment } from 'react';
import { TFunction } from 'i18next';
import { Box, Card } from '@mui/material';

// First-Party
import Typeface from '../Typeface';
import AnnouncementsCard from './Announcements.card';
import { Announcement } from './Announcements.interfaces';

interface AnnouncementListProps {
  announcementList: Announcement[];
  unreadAnnouncements?: boolean;
  t: TFunction<"translation", undefined>;
}

const AnnouncementList: FC<AnnouncementListProps> = ({
  announcementList,
  unreadAnnouncements = false,
  t
}) => {
  return (
    <Box className="announcement-list">
      {announcementList.length === 0 && (
        <Box className="announcement-list-empty">
          <Typeface textVariant='heading_1'>
            {t(unreadAnnouncements
              ? "msg.announcements.no-unread"
              : "msg.announcements.no-announcements"
            )}
          </Typeface>
        </Box>
      )}

      {(announcementList).map((announcement) => (
          <Fragment key={announcement.id}>
            {announcement?.id ? (
              <>
                <AnnouncementsCard
                  announcement={announcement}
                  t={t}
                />
              </>
            ) : (
              <Card className="announcement-list-empty">
                <Typeface textVariant='heading_1'>
                  {t("msg.announcements.no-details")}
                </Typeface>
              </Card>
            )}
          </Fragment>
        )
      )}
    </Box>
  );
};

export default AnnouncementList;
